import { lazy } from 'react';
import { Route } from 'react-router';

import { CLIENTS_ROUTES } from './routes';

const RootLayout = lazy(() => import('@corify/layout/root-layout'));
const MainClientClientDetails = lazy(() => import('@corify/pages/clients/client/client'));
const MainClientClients = lazy(() => import('@corify/pages/clients/clients/clients'));

export const getClientsRoutes = () => [
  <Route key="clients" element={<RootLayout />}>
    <Route path={CLIENTS_ROUTES.CLIENTS_ROUTE} index element={<MainClientClients />} />
    <Route path={CLIENTS_ROUTES.CLIENTS_DETAIL_ROUTE} element={<MainClientClientDetails />} />
  </Route>,
];
