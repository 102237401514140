import { Order } from '@corify/components/sort-field/sort-field';
import { useCallback, useMemo } from 'react';
import { useSearchParams } from 'react-router';

type PaginationProps = {
  page?: number;
  pageSize?: number;
};

type SortProps = {
  sort?: string;
  sortDirection?: Order;
};

type BaseNavigationProps = PaginationProps & SortProps;

export function useCustomSearchParams<TData extends Record<string, unknown>, T = BaseNavigationProps & TData>() {
  const [searchParams, setSearchParams] = useSearchParams();

  const setCustomSearchParams = (properties: Partial<T>) => {
    setSearchParams(previousParams => {
      const searchParamsObj = Object.fromEntries([...previousParams]);
      const combinedParamsObj = { ...searchParamsObj, ...properties };

      return Object.fromEntries(
        Object.entries(combinedParamsObj)
          .map(([key, value]) => [key, value?.toString()])
          .filter(([, value]) => !!value)
      );
    });
  };
  const getCustomSearchParam = useCallback(
    (key: keyof BaseNavigationProps | keyof TData) => searchParams.get(key as string),
    [searchParams]
  );

  const baseProps: BaseNavigationProps = useMemo(
    () => ({
      page: getCustomSearchParam('page') ? Number(getCustomSearchParam('page')) : undefined,
      pageSize: getCustomSearchParam('pageSize') ? Number(getCustomSearchParam('pageSize')) : undefined,
      sort: getCustomSearchParam('sort') ?? undefined,
      sortDirection: (getCustomSearchParam('sortDirection') as Order) ?? undefined,
    }),
    [getCustomSearchParam]
  );

  return {
    setCustomSearchParams,
    getCustomSearchParam,
    ...baseProps,
  };
}
