import * as Sentry from '@sentry/react';

type SeverityLevel = 'fatal' | 'error' | 'warning' | 'log' | 'info' | 'debug';
type Context = Record<string, unknown>;
type Primitive = number | string | boolean | bigint | symbol | null | undefined;

export type EventContext = {
  level?: SeverityLevel;
  tags?: {
    [key: string]: Primitive;
  };
  originalError?: Error;
  context?: Context;
};

export const logException = (
  error: Error | string,
  { level = 'error', context, tags, originalError }: Partial<EventContext> = {}
) => {
  const errorInstance = typeof error === 'string' ? new Error(error) : error;
  const extra = { originalError, customContext: context };

  Sentry.captureException(errorInstance, { level, tags, extra });
};

export const logRequestException = (endpoint: string, url: string, originalError?: Error) =>
  logException(`Request Failure ${endpoint}`, { originalError, context: { url } });
