import { lazy } from 'react';
import { Route } from 'react-router';

import { USERS_ROUTES } from './routes';

const RootLayout = lazy(() => import('@corify/layout/root-layout'));
const MainClientUserDetails = lazy(() => import('@corify/pages/user-details/user-detail'));
const MainClientUsers = lazy(() => import('@corify/pages/users/users'));

export const getUsersRoutes = () => [
  <Route key="users" element={<RootLayout />}>
    <Route path={USERS_ROUTES.USERS_ROUTE} element={<MainClientUsers />} />
    <Route path={USERS_ROUTES.USERS_DETAIL_ROUTE} element={<MainClientUserDetails />} />
  </Route>,
];
