import ClientTypeCheckLayout from '@corify/layout/security/client-type-check-layout';
import { lazy } from 'react';
import { Route } from 'react-router';

import { FLEET_MANAGEMENT_ROUTES } from './routes';

const RootLayout = lazy(() => import('@corify/layout/root-layout'));

const AvailableData = lazy(() => import('@corify/pages/fleet-management/available-data/available-data'));
const PausedImports = lazy(() => import('@corify/pages/fleet-management/paused-imports/paused-imports'));
const FinishedImports = lazy(() => import('@corify/pages/fleet-management/finished-imports/finished-imports'));

const NotificationsUpload = lazy(
  () => import('@corify/pages/fleet-management/notifications-upload/notifications-upload')
);

export const getFleetManagementRoutes = () => [
  <Route key="fleet-management-list" element={<RootLayout />}>
    <Route path={FLEET_MANAGEMENT_ROUTES.AVAILABLE_DATA} element={<AvailableData />} />
    <Route path={FLEET_MANAGEMENT_ROUTES.PAUSED_IMPORTS} element={<PausedImports />} />
    <Route path={FLEET_MANAGEMENT_ROUTES.FINISHED_IMPORTS} element={<FinishedImports />} />
    <Route
      path={FLEET_MANAGEMENT_ROUTES.NOTIFICATIONS_UPLOAD}
      element={<ClientTypeCheckLayout validate={['CFM_ADMIN']} />}
    >
      <Route element={<NotificationsUpload />} index />
    </Route>
  </Route>,
];
