import { Environment } from '@corify/types/environments';
import * as Sentry from '@sentry/react';
import { useEffect } from 'react';
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router';

const replayLessEnvs: Environment[] = ['production', 'pre-prod', 'test'];

export const sentryInit = (environment: Environment, version: string) =>
  Sentry.init({
    dsn: 'https://b158517514602891459ae761d7aa6866@o4506297017106432.ingest.sentry.io/4506315177787392',
    environment: environment,
    enabled: environment !== 'local',
    release: version,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.reactRouterV7BrowserTracingIntegration({
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
      Sentry.replayIntegration(),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, // Capture 100% of the transactions
    // Session Replay
    replaysSessionSampleRate: replayLessEnvs.includes(environment) ? 0 : 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: replayLessEnvs.includes(environment) ? 0 : 0.5, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
