import { lazy } from 'react';
import { Route } from 'react-router';

import { ADMIN_ROUTES } from './routes';

const Accesses = lazy(() => import('@corify/pages/admin/accesses/accesses'));
const AccessesDetail = lazy(() => import('@corify/pages/admin/accesses-detail/access-details'));
const ClientDetail = lazy(() => import('@corify/pages/admin/client-details/client-details'));
const Clients = lazy(() => import('@corify/pages/admin/clients/clients'));
const InternalAdminLayout = lazy(() => import('@corify/layout/internal-admin-layout'));
const InternalAdminClientLayout = lazy(() => import('@corify/layout/internal-admin-client-layout'));

export const getAdminRoutes = () => [
  <Route key="admin-client-list" element={<InternalAdminLayout />}>
    <Route path={ADMIN_ROUTES.CLIENTS_ROUTE} index element={<Clients />} />
  </Route>,
  <Route key="admin-client-accesses" element={<InternalAdminClientLayout />}>
    <Route path={ADMIN_ROUTES.CLIENTS_DETAIL_ROUTE} element={<ClientDetail />} />
    <Route path={ADMIN_ROUTES.CLIENT_ACCESS_ROUTE} element={<Accesses />} />
    <Route path={ADMIN_ROUTES.CLIENT_ACCESS_DETAIL_ROUTE} element={<AccessesDetail />} />
  </Route>,
];
