export const environmentMap = {
  development: 'dev',
  staging: 'staging',
  'pre-prod': 'pre-prod',
  production: 'prod',
  local: 'local',
  test: 'test',
};

export type Environment = keyof typeof environmentMap;
