import { Icon } from '@corify/components/icon/icon';
import { cn } from '@corify/helpers/cn';
import { Table } from '@tanstack/react-table';
import { Fragment, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { useCorifyTable } from '../../provider/use-corify-table';
import { PaginationButton } from './pagination-button';
import { usePagination } from './use-pagination';

type Props = {
  className?: string;
  table: Table<any>;
};

export const DEFAULT_PAGE_SIZE = 10;

export const Pagination = ({ className, table }: Props) => {
  const {
    pagination: { pageIndex },
    setPagination,
  } = useCorifyTable<unknown, unknown>();
  const { t } = useTranslation();

  const totalPages = table.getPageCount();

  const { pages } = usePagination({ pageIndex, totalPages });

  useEffect(() => {
    if (pageIndex > totalPages - 1 || pageIndex < 0) {
      setPagination(prev => ({ ...prev, pageIndex: 0 }));
    }
  }, [pageIndex, setPagination, totalPages]);

  if (pages.length < 2) {
    return null;
  }

  return (
    <div className={cn('flex justify-center py-4 shadow-[rgb(224,225,255)_0px_2px_4px_-1px_inset]', className)}>
      <PaginationButton
        className="mr-2"
        isDisabled={pageIndex === 0}
        onClick={() => setPagination(prev => ({ ...prev, pageIndex: prev.pageIndex - 1 }))}
        name={t('components.pagination.previous')}
      >
        <Icon name="expand_less" className="-rotate-90" />
      </PaginationButton>

      {pages.map((page, index) => (
        <Fragment key={index}>
          {page.renderMoreIcon === 'left' && <div className="text-grey">...</div>}
          <PaginationButton
            isActive={page.active}
            className="mx-2"
            isDisabled={page.disabled}
            onClick={() => setPagination(prev => ({ ...prev, pageIndex: page.pageIndex }))}
          >
            {page.pageLabel}
          </PaginationButton>
          {page.renderMoreIcon === 'right' && <div className="text-grey">...</div>}
        </Fragment>
      ))}

      <PaginationButton
        className="ml-2"
        isDisabled={pageIndex >= totalPages - 1}
        onClick={() => setPagination(prev => ({ ...prev, pageIndex: prev.pageIndex + 1 }))}
        name={t('components.pagination.next')}
      >
        <Icon name="expand_less" className="rotate-90" />
      </PaginationButton>
    </div>
  );
};
