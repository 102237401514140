import { Button } from '@corify/components/button/button';
import { Paper } from '@corify/components/paper/paper';
import { useSwrApiFetcher } from '@corify/components/swr-provider/use-swr-api-fetcher';
import { toast } from '@corify/components/toast/toast';
import { messages } from '@corify/constants/messages';
import { apiUrl } from '@corify/helpers/api/api';
import { logException } from '@corify/logging/log';
import { endpoints } from '@corify/services/frontend-api/endpoints';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import useSWRMutation from 'swr/mutation';

import { UploadErrorResponse } from './types';

interface HsnTsnFilesForm {
  dictionary: File[];
}

const ModelManagerHsnTsn = () => {
  const { t } = useTranslation();
  const swrApiFetcher = useSwrApiFetcher();

  const {
    formState: { dirtyFields, isSubmitting },
    handleSubmit,
    register,
  } = useForm<HsnTsnFilesForm>({
    defaultValues: {
      dictionary: undefined,
    },
    mode: 'onTouched',
  });

  const { trigger } = useSWRMutation(apiUrl(endpoints.crom.hsnTsnDictionary), swrApiFetcher());

  const handleSave = async (data: HsnTsnFilesForm) => {
    const formData = new FormData();

    formData.append('hsn-tsn-dictionary', new Blob([data.dictionary[0]], { type: 'form-data' }));

    try {
      await trigger({
        body: formData,
      });

      toast(messages.changesSuccessfullySaved, { type: 'success' });
    } catch (error: unknown) {
      const typedError = error as { body: UploadErrorResponse };

      const errorBody = typedError.body;

      if (errorBody.status === 403) {
        toast(t('errors.forbiddenWithPermissionHint', { permission: 'publish_new_dictionary' }), { type: 'error' });
      } else {
        logException(t('errors.unhandled'), { originalError: errorBody });
        toast(t('errors.saveError'), { type: 'error' });
      }
    }
  };

  const isDirty = Object.keys(dirtyFields).length > 0;

  return (
    <div className="grid min-h-full grid-rows-[auto_1fr]">
      <h2 className="mb-2.5">Model Manager</h2>

      <div className="flex flex-col pb-6">
        <div className="flex justify-between">
          <h3 className="py-6">HSN/TSN upload</h3>
        </div>

        <Paper compact>
          <form data-testid="form" onSubmit={handleSubmit(handleSave)}>
            <div className="mb-8 flex flex-col">
              <label htmlFor="dictionary_file_input" className="text-sm font-semibold text-gray-400">
                HSN/TSN <span className="align-baseline text-corifyRed">*</span>
              </label>
              <input
                aria-describedby="dictionary_file_input"
                id="dictionary"
                data-testid="dictionary"
                {...register('dictionary', { required: true })}
                type="file"
                accept=".txt"
                required
                className="w-full cursor-pointer rounded border bg-white text-sm font-semibold text-gray-400 file:mr-4 file:cursor-pointer file:border-0 file:bg-gray-100 file:px-4 file:py-3 file:text-gray-500 file:hover:bg-gray-200"
              />
            </div>

            <div className="flex items-center">
              <Button
                data-testid="submit"
                variant="primary"
                isDisabled={!isDirty || isSubmitting}
                loading={isSubmitting}
                size="small"
                type="submit"
              >
                {t('common.submit')}
              </Button>
            </div>
          </form>
        </Paper>
      </div>
    </div>
  );
};

export default ModelManagerHsnTsn;
