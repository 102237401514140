import { lazy } from 'react';
import { Route } from 'react-router';

import { NO_CLIENT_ROUTES } from './routes';

const BaseLayout = lazy(() => import('@corify/layout/base-layout').then(module => ({ default: module.BaseLayout })));
const NoClientSelectedLayout = lazy(() => import('@corify/layout/no-client-selected-layout'));
const NoClientAvailable = lazy(() => import('@corify/pages/no-client-available/no-client-available'));
const NoClientSelected = lazy(() => import('@corify/pages/no-client-selected/no-client-selected'));

export const getClientCheckerRoutes = () => [
  <Route key="no-client" element={<BaseLayout />}>
    <Route path={NO_CLIENT_ROUTES.NO_CLIENT_DEFINED_ROUTE} element={<NoClientAvailable />} />
  </Route>,
  <Route key="no-client-selected" element={<NoClientSelectedLayout />}>
    <Route path={NO_CLIENT_ROUTES.NO_CLIENT_SELECTED_ROUTE} element={<NoClientSelected />} />
  </Route>,
];
