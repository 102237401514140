import { lazy } from 'react';
import { Route } from 'react-router';

import { MY_TASKS_ROUTES } from './routes';

const RootLayout = lazy(() => import('@corify/layout/root-layout'));

const MyTasks = lazy(() => import('@corify/pages/my-tasks/my-tasks'));

export const getMyTasksRoutes = () => [
  <Route key="my-tasks-list" element={<RootLayout />}>
    <Route path={MY_TASKS_ROUTES.MY_TASKS} element={<MyTasks />} />
  </Route>,
];
