import { useAvailableClients } from '@corify/context/client/client-provider';
import { BASE_ROUTES } from '@corify/routes/routes';
import { UserRole } from '@corify/types/user';
import { FC, ReactElement } from 'react';
import { Navigate, Outlet } from 'react-router';

type Props = {
  validate: UserRole[];
  children?: ReactElement;
};

const ClientTypeCheckLayout: FC<Props> = ({ validate, children }) => {
  const clients = useAvailableClients();
  const clientUserRoles = clients.flatMap(client => client.userRoles);

  let isValid = false;

  validate?.forEach((role: UserRole) => {
    if (!isValid && clientUserRoles.includes(role)) {
      isValid = true;
    }
  });

  if (!isValid) {
    return <Navigate to={BASE_ROUTES.NOT_FOUND_ROUTE} replace />;
  }

  return children || <Outlet />;
};
export default ClientTypeCheckLayout;
