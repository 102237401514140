import { LegalContextProvider } from '@corify/context/legal/legal-context';
import { LegalLayout } from '@corify/layout/legal-layout';
import { Route } from 'react-router';

import { LEGAL_ROUTES } from './routes';

const navigationItems = LEGAL_ROUTES.map(({ name, path }) => ({ name, path }));

export const getLegalRoutes = () => (
  <Route
    key="legal"
    element={
      <LegalContextProvider>
        <LegalLayout navigationItems={navigationItems} />
      </LegalContextProvider>
    }
  >
    {LEGAL_ROUTES.map(({ name, path, component: Component }) => (
      <Route key={name} path={path} element={<Component />} />
    ))}
  </Route>
);
